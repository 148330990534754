import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "../../components/seo"
import ProjectsData from "../../data/projects"
import InnerLayout from "../../components/InnerLayout"
import InnerWrapper from "../../components/InnerWrapper"
import ProjectHero from "../../components/project/Hero"
import ProjectWrapper from "../../components/project/Wrapper"
import ProjectIntro from "../../components/project/Intro"
import ProjectSection from "../../components/project/Section"
import BankbudgettetCompOne from "../../components/project/bankbudgettet/BankbudgettetCompOne"
import BankbudgettetCompTwo from "../../components/project/bankbudgettet/BankbudgettetCompTwo"
import ProjectNext from "../../components/project/Next"

const Bankbudgettet = (pageContext) => {
  const projectsData = ProjectsData();
  const projectId = 5;
  const projectData = projectsData.find(data => data.id === projectId);

  const images = useStaticQuery(graphql`
    query {
      bankbudgettetMockupOne: file(relativePath: { eq: "bankbudgettet/bankbudgettet-home-mockup.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bankbudgettetImage: file(relativePath: { eq: "bankbudgettet/bankbudgettet-image.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const contentSectionOne = (
    <div>
      <h3>User-friendly budget generation tool</h3>
      <p>A welcoming web platform that allows you to create budgets, save them to the cloud, collaborate and generate easy readable PDF reports. Including budgetting tips and tricks and an auto generated guiding economy assessment based on the budget.</p>
      <ul className="list-unstyled"><li>- Authentication system</li><li>- User-friendly and simple budget maker</li><li>- Share budgets for collaboration</li><li>- Print and download generated budget PDF</li><li>- Increase your chances of getting a loan</li><li>- Budgetting suggestions based on user data</li><li>- Ability to create monthly or yearly overview</li><li>- Chart visualization</li><li>- Blogging module for economy tips</li><li>- Budget personalization</li><li>- Multiple budgets manager</li></ul>
    </div>
  );
  
  return (
    <InnerLayout noTopPadding>
      <SEO title="Bankbudgettet project" bodyClass="ProjectPage" />
      <ProjectHero 
        title={projectData.title}
        subtitle={projectData.excerpt}
        color={projectData.color}
        image={projectData.image}
      />
      <ProjectWrapper>
        <InnerWrapper>
          <ProjectIntro
            client={projectData.client}
            website={projectData.website}
            agency={projectData.agency}
            year={projectData.year}
            category={projectData.category}
            role={projectData.role}
            technologies={projectData.technologies}
            problem="To present a detailed overview of your personal economy (present and future) can be challenging. None the less this is exactly what you are asked to do when you want to loan money or get a mortgage for investment in a house, flat or property. In every case, the bank wants to know how you assess and estimate your own economy. What are your expenses, what is your income, how do you spend your money? Based on this information (and other factors) a financial institution can assess your economic situation and whether you will be eligible for a bank loan."
            solution="Instead of dusty Excel spreadsheets and branded bank templates, what if you could type in your budget estimation straight into an application in your browser? This is what the Danish platform Bankbudgettet provides. A user friendly overview for you to type in your expenses and automatically generate a report showing your bank that you are on top of your finances."
          />
          <ProjectSection
            type="columns"
            bgcolor="lightgray"
            content={contentSectionOne}
            images={[images.bankbudgettetMockupOne.childImageSharp.fluid]}
          />
          <BankbudgettetCompOne />
          <BankbudgettetCompTwo />
          <ProjectSection
            bgcolor="white"
            images={[images.bankbudgettetImage.childImageSharp.fluid]}
          />
          <ProjectNext project={0} />
        </InnerWrapper>
      </ProjectWrapper>
    </InnerLayout>
  )
}

export default Bankbudgettet