import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const BankbudgettetCompTwo = () => {

  const data = useStaticQuery(graphql`
    query {
      imageOne: file(relativePath: { eq: "bankbudgettet/bankbudgettet-app-mockup.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1400, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageTwo: file(relativePath: { eq: "bankbudgettet/bankbudgettet-blog-mockup.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1400, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className="ProjectSection ProjectSection--BBtwo outer-padding-y outer-padding-x bg-lightgray">
      <div className="grid-x grid-padding-x">
        <div className="cell medium-6">
          <div className="fadein">
            <Img fluid={data.imageOne.childImageSharp.fluid} />
          </div>
        </div>
        <div className="cell medium-6">
          <div className="fadein">
            <Img fluid={data.imageTwo.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default BankbudgettetCompTwo