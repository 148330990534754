import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

import chartGif from '../../../images/bankbudgettet/bankbudgettet-chart.gif'

const BankbudgettetCompOne = () => {

  const data = useStaticQuery(graphql`
    query {
      imageOne: file(relativePath: { eq: "bankbudgettet/bankbudgettet-ui-mockup-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageTwo: file(relativePath: { eq: "bankbudgettet/bankbudgettet-ui-mockup-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageThree: file(relativePath: { eq: "bankbudgettet/bankbudgettet-ui-mockup-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className="ProjectSection ProjectSection--BBone outer-padding-y outer-padding-x bg-white">
      <div className="fadein">
        <Img fluid={data.imageOne.childImageSharp.fluid} />
      </div>
      <div>
        <div className="fadein">
          <Img fluid={data.imageTwo.childImageSharp.fluid} />
        </div>
        <div className="fadein">
          <Img fluid={data.imageThree.childImageSharp.fluid} />
        </div>
        <div className="fadein">
          <img src={chartGif} alt="Pie chart animation"/>
        </div>
      </div>
    </section>
  )
}

export default BankbudgettetCompOne